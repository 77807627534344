import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { FormattedMessage } from 'react-intl';
import Layout from '../components/Layout';
import Projects from '../scenes/Projects';
import HeadMeta from '../components/HeadMeta';

const {
  REACT_COMPANY_NAME,
} = process.env;

const metaData = {
	en: {
		title: `TriDyme | Projects`,
		pageTitle: "Projects",
		description: "TriDyme projects presentation"
	},
	fr: {
		title: `TriDyme | Projets`,
		pageTitle:  "Projets",
		description: "TriDyme présentation des projets"
	}
};

const ProjectsPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeadMeta 
      locale={locale} 
      metaData={metaData}
    />
    <Projects />
  </Layout>
)

ProjectsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default ProjectsPage;