import React from 'react';
import { FormattedMessage } from 'react-intl';

const Projects = () => {
	return (
		<div>
			<h1>Projects</h1>
		</div>
	);
};

export default Projects;